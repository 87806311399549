import React from "react";
import "../styles/Sections/Home.css";

interface HomeProps {
  title: string;
  tagline: string;
  description: string;
}

const { REACT_APP_S3_URL, REACT_APP_WORKPLAY_URL } = process.env;

const Home: React.FC = () => {
  const homeContent: HomeProps = {
    title: "Gamified Digital Marketing Solutions",
    tagline: "We Work. They Play. We all Win !",
    description:
      "We provide gamified and customized digital engagement opportunities between brands and human beings leading to enhanced brand love score.",
  };

  const taglineParts = homeContent.tagline.split(/(Work\.|Play\.|Win !)/);

  const handleGamePageRedirect = () => {
    window.location.href = `${REACT_APP_WORKPLAY_URL}/game`;
  };

  return (
    <section className="w-full bg-black px-8 py-12 text-white sm:px-12 md:py-16 lg:flex lg:items-center lg:justify-between lg:p-16 xl:px-10 xl:py-[80px] 3xl:p-40">
      <div className="home-content m-auto max-w-[330px] text-left xsm:max-w-[360px] min-[480px]:max-w-full">
        <h1 className="m-auto text-center text-2xl font-bold tracking-wide max-[330px]:text-[20px] xsm:text-[2rem] xsm:leading-tight md:text-3xl lg:max-w-[400px] lg:text-left lg:text-4xl lg:leading-[50px] xl:max-w-[480px] xl:text-[40px] 3xl:ml-0 3xl:text-5xl 3xl:leading-snug">
          {homeContent.title}
        </h1>
        <h3 className="mb-8 mt-4 text-center text-[20px] font-bold xsm:text-xl md:text-2xl lg:text-left xl:text-3xl 3xl:text-4xl">
          {taglineParts.map((part, index) => {
            if (part === "Work." || part === "Play." || part === "Win !") {
              return (
                <span className="pb-2 text-apple" key={index}>
                  {part}
                </span>
              );
            } else {
              return part;
            }
          })}
        </h3>
        <p className="m-auto text-center text-base font-semibold leading-7 md:max-w-[600px] md:text-[17px] lg:ml-0 lg:max-w-[400px] lg:text-left xl:max-w-[480px]">
          {homeContent.description}
        </p>
        <div
          className="m-auto my-8 w-36 xsm:my-10 lg:ml-0 lg:w-48 xl:mb-0 xl:mt-12"
          onClick={handleGamePageRedirect}
        >
          <button className="cursor-pointer rounded-full border-2 border-black bg-apple px-6 py-[10px] text-base font-semibold uppercase text-black shadow-navBtn sm:text-lg lg:px-9 lg:py-3">
            Play Now
          </button>
        </div>
      </div>
      <img
        className="m-auto xsm:h-48 min-[480px]:h-56 md:h-64 lg:h-72 xl:my-0 xl:h-[420px]"
        src={`${REACT_APP_S3_URL}/assets/gamify-startup.png`}
        alt="gamify startup"
      />
    </section>
  );
};

export default Home;
