import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";

interface VerifyMailProps {}

const { REACT_APP_S3_URL, REACT_APP_WORKPLAY_URL, REACT_APP_API_URL } =
  process.env;

const VerifyMail: React.FC<VerifyMailProps> = ({}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const URL = `${REACT_APP_API_URL}/auth/verify-email`;
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get("code");
  const [verificationStatus, setVerificationStatus] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const verifyAccount = async () => {
      try {
        if (!URL) {
          return;
        }

        const response = await axios.patch(
          `${REACT_APP_API_URL}/auth/verify-email/${code}`,
        );
        if (response.data.success === true) {
          sessionStorage.setItem("isVerified", "true");
          setVerificationStatus("Verification successful!");
        } else {
          throw new Error("Oops! Something went wrong!");
        }
      } catch (error: any) {
        console.error("Error verifying account:", error);
        setVerificationStatus("Oops! Something went wrong!");
      } finally {
        setLoading(false);
      }
    };

    const isVerified = sessionStorage.getItem("isVerified");
    if (isVerified === "true") {
      setVerificationStatus("Your account has already been verified.");
      setLoading(false);
    } else {
      verifyAccount();
    }
  }, [URL, code]);

  const handleLogoRedirect = () => {
    navigate("/");
  };

  return (
    <>
      {loading ? (
        <div className="flex h-screen items-center justify-center">
          <ClipLoader size={40} color="#949393" />
        </div>
      ) : (
        <div className="ck h-screen w-full bg-white text-center font-normal text-black">
          <div className="flex h-[82px] items-center bg-[#6fd1b5] px-6 shadow-btn max-[900px]:h-[70px] sm:px-12 lg:py-6">
            <img
              className="h-5 cursor-pointer sm:h-25"
              src={`${REACT_APP_S3_URL}/assets/wp-logo.png`}
              alt="workplay-logo"
              onClick={handleLogoRedirect}
            />
          </div>
          <div className="verify-container flex items-center justify-center">
            {verificationStatus === "Verification successful!" ? (
              <div>
                <img
                  src={`${REACT_APP_S3_URL}/assets/mail-open.png`}
                  className="m-auto flex w-[240px] text-center lg:w-[340px]"
                  alt="verify-mail"
                />
                <p className="m-auto mt-8 w-[250px] text-lg tracking-wide lg:w-full lg:text-xl">
                  {verificationStatus}
                </p>
                <p className="m-auto mt-2 w-[300px] text-[15px] lg:w-full lg:text-base">
                  Thank you for confirming your email address. You can now{" "}
                  <a
                    className="font-medium text-green-700 underline"
                    href={`${REACT_APP_WORKPLAY_URL}/login`}
                  >
                    log in
                  </a>{" "}
                  .
                </p>
              </div>
            ) : (
              <div className="flex items-center justify-center">
                <div>
                  {verificationStatus ===
                  "Your account has already been verified." ? (
                    <>
                      <img
                        src={`${REACT_APP_S3_URL}/assets/mail-open.png`}
                        className="m-auto flex w-[240px] text-center lg:w-[340px]"
                        alt="verify-mail"
                      />
                      <p className="m-auto mt-8 w-[250px] text-lg tracking-wide lg:w-full lg:text-xl">
                        {verificationStatus}
                      </p>{" "}
                      <p className="m-auto mt-2 w-[300px] text-[15px] lg:w-full lg:text-base">
                        Thank you for confirming your email address. You can now{" "}
                        <a
                          className="font-medium text-green-700 underline"
                          href={`${REACT_APP_WORKPLAY_URL}/login`}
                        >
                          log in
                        </a>{" "}
                        .
                      </p>
                    </>
                  ) : (
                    <>
                      <img
                        src={`${REACT_APP_S3_URL}/assets/mail-box.png`}
                        className="m-auto flex w-[240px] text-center lg:w-[340px]"
                        alt="verify-mail"
                      />
                      <p className="m-auto mt-8 w-[250px] text-lg tracking-wide lg:w-full lg:text-xl">
                        {verificationStatus}
                      </p>{" "}
                      <p className="m-auto mt-2 w-[300px] text-[15px]  lg:w-full lg:text-base">
                        Please try again later or{" "}
                        <a
                          className="font-medium text-green-700 underline"
                          href={`${REACT_APP_WORKPLAY_URL}/contact`}
                        >
                          contact us
                        </a>{" "}
                        for assistance.
                      </p>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
          <ToastContainer />
        </div>
      )}
    </>
  );
};

export default VerifyMail;
