import React from "react";
import "../styles/Sections/VisionMission.css";

interface SectionData {
  title: string;
  content: string;
  icon: string;
  customClass: string;
}

const { REACT_APP_S3_URL } = process.env;

const VisionMission: React.FC = () => {
  const visionMissionData: SectionData[] = [
    {
      title: "Our Vision",
      content:
        "To be the best gamification platform which enhances the engagement between brands and human beings.",
      icon: `${REACT_APP_S3_URL}/assets/our-vision.png`,
      customClass: "vision",
    },
    {
      title: "Our Mission",
      content:
        "We enhance the brand value of all our customers through affordable, accessible, and innovative gamified solutions.",
      icon: `${REACT_APP_S3_URL}/assets/our-mission.png`,
      customClass: "mission",
    },
  ];

  return (
    <section
      id="vision-mission"
      className="bg-[#ffda24] px-8 py-10 text-center font-bold text-[#292929] sm:px-10 sm:py-16 lg:p-16 xl:px-32 xl:py-16 3xl:py-28"
    >
      <img
        className="m-auto h-[210px] min-[340px]:h-[240px] min-[480px]:h-[320px] 2xl:h-[420px]"
        src={`${REACT_APP_S3_URL}/assets/person-leading-a-team.png`}
        alt="person leading a team"
      />

      <div className="text-left lg:flex lg:items-center lg:justify-between lg:text-center">
        {visionMissionData.map((data, index) => (
          <div key={index} className={`${data.customClass}`}>
            <div
              className={`${data.customClass}-heading flex w-auto items-center justify-start py-5 text-xl sm:py-10 sm:text-2xl lg:justify-center xl:text-3xl`}
            >
              <img src={data.icon} className="h-6 sm:h-7" alt={data.title} />
              <h3 className="ml-3">{data.title}</h3>
            </div>
            <div className={`${data.customClass}`}>
              <p className="w-auto text-wrap text-base xl:text-lg">
                {data.content}
              </p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default VisionMission;
