import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { getCampaignGraphData } from "../../../features/Campaigns/campaignSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  CurrentCampaign,
  CampaignGraphData,
  AllCampaigns,
} from "../../../features/Campaigns/campaignSlice";
import AreaGraph from "./AreaGraph";

const { REACT_APP_S3_URL } = process.env;

interface ChartData {
  date: string;
  totalImpressions: number;
  totalSubmissions: number;
}

interface ModifiedData {
  Impressions: number;
  Submissions: number;
  date: string;
}

interface SimpleLineChartProps {
  Id: string | undefined;
}

const SimpleLineChart: React.FC<SimpleLineChartProps> = ({ Id }) => {
  const [filter, setFilter] = useState<string>("All Time");
  const [selectedGraph, setSelectedGraph] = useState<string>("All");

  const formatDate = (date: any) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const today = new Date();

  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const dispatch = useAppDispatch();
  const graphData = useAppSelector(CampaignGraphData) || [];
  const Campaigns = useAppSelector(AllCampaigns);

  function renameKeys(data: ChartData[]): ModifiedData[] {
    // Check if the data array is empty
    if (data.length === 0) {
      return [];
    }

    return data.map(({ totalImpressions, totalSubmissions, date }) => ({
      Impressions: totalImpressions,
      Submissions: totalSubmissions,
      date,
    }));
  }

  const handleFilter = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFilter(event.target.value);
    const element = Campaigns.find((item) => item.id === Id);
    if (event.target.value === "This Week") {
      // Today's date
      const startOfWeek = new Date(today);
      startOfWeek.setDate(startOfWeek.getDate() - today.getDay()); // Set start of week to Sunday
      const fromDate = startOfWeek;
      const toDate = today; // Today's date
      toDate.setDate(toDate.getDate() + 1); // Add one day to include the current day
      const fromDateString = formatDate(fromDate); // Format fromDate
      const toDateString = formatDate(toDate); // Format toDate
      setFrom(fromDateString);
      setTo(toDateString);
    } else if (event.target.value === "This Month") {
      const fromDate = new Date(today.getFullYear(), today.getMonth(), 1);
      const toDate = today;
      toDate.setDate(toDate.getDate() + 1);
      const fromDateString = formatDate(fromDate);
      const toDateString = formatDate(toDate);
      setFrom(fromDateString);
      setTo(toDateString);
    } else if (event.target.value === "This Year") {
      const fromDate = new Date(today.getFullYear(), 0, 1);
      const toDate = today;
      toDate.setDate(toDate.getDate() + 1);
      const fromDateString = formatDate(fromDate);
      const toDateString = formatDate(toDate);
      setFrom(fromDateString);
      setTo(toDateString);
    } else if (event.target.value === "All Data") {
      setFrom("");
      setTo("");
    }
  };

  const handleSelectGraph = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedGraph(event.target.value);
  };

  function isEndDateInCurrentMonth(date: string): boolean {
    const givenDate = new Date(date);
    const currentDate = new Date();

    return (
      givenDate.getFullYear() === currentDate.getFullYear() &&
      givenDate.getMonth() === currentDate.getMonth()
    );
  }

  function isEndDateInCurrentWeek(date: string): boolean {
    const givenDate = new Date(date);
    const currentDate = new Date();

    const startOfWeek = new Date(
      currentDate.setDate(currentDate.getDate() - currentDate.getDay()),
    );

    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    console.log(givenDate <= endOfWeek);

    return givenDate <= endOfWeek;
  }

  function isEndDateInCurrentYear(date: string): boolean {
    const givenDate = new Date(date);
    const currentDate = new Date();

    return givenDate.getFullYear() === currentDate.getFullYear();
  }

  useEffect(() => {
    if (Campaigns.length > 0) {
      const element = Campaigns.find((item) => item.id === Id);
      if (element?.id && element.gameId) {
        dispatch(
          getCampaignGraphData({
            ccid: element.id,
            gameId: element.gameId,
            fromDate: from,
            toDate: to,
          }),
        );
      }
    }
  }, [Id, from, to]);

  const element = Campaigns.find((item) => item.id === Id);

  return (
    <div>
      <div className="my-4 mr-0 flex items-center justify-end gap-x-4">
        <select
          id="graph-select"
          className="flex w-fit justify-end rounded-md border p-2 text-left outline-none"
          onChange={handleSelectGraph}
        >
          <option value="All">All</option>
          <option value="Impressions">Impressions</option>
          <option value="Submissions">Submissions</option>
        </select>{" "}
        {
          <select
            id="filter-select"
            className="flex w-fit justify-end rounded-md border p-2 text-left outline-none"
            value={filter}
            onChange={handleFilter}
          >
            <option value="All Data">All Time</option>
            <option value="This Year">This Year</option>
            <option value="This Month">This Month</option>
            <option value="This Week">This Week</option>
          </select>
        }
      </div>
      {selectedGraph === "All" && graphData ? (
        renameKeys(graphData).length > 0 ? (
          <ResponsiveContainer width="100%" height={350}>
            <LineChart
              width={500}
              height={300}
              data={renameKeys(graphData)}
              margin={{
                top: 5,
                right: 10,
                left: 0,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="Impressions"
                stroke="#ee8251"
                activeDot={{ r: 8 }}
              />
              <Line type="monotone" dataKey="Submissions" stroke="#82ca9d" />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <div>
            {" "}
            <div className="m-auto flex flex-col items-center justify-center overflow-hidden text-center">
              <img
                src={`${REACT_APP_S3_URL}/assets/analytics.png`}
                className=" h-56 text-center lg:h-[300px]"
                alt="no data found"
              />
              <p className=" font-poppins text-[15px] font-medium lg:text-base">
                No data available.
              </p>
            </div>
          </div>
        )
      ) : (
        graphData &&
        (renameKeys(graphData).length > 0 ? (
          <AreaGraph
            data={renameKeys(graphData)}
            dataKey={selectedGraph as "Impressions" | "Submissions"}
            filter={filter}
          />
        ) : (
          <div>
            {" "}
            <div className="m-auto flex flex-col items-center justify-center overflow-hidden text-center">
              <img
                src={`${REACT_APP_S3_URL}/assets/analytics.png`}
                className=" h-56 text-center lg:h-[300px]"
                alt="no data found"
              />
              <p className=" font-poppins text-[15px] font-medium lg:text-base">
                No data available for this project
              </p>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default SimpleLineChart;
