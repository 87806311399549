import React, { ChangeEvent, FC, useState } from "react";
import Footer from "./Footer";
import axios from "axios";
import Navbar from "./Navbar";
import "../styles/Sections/Contact.css";
import { CountryData } from "react-phone-input-2";
import countriesData from "../assets/countriesData.json";
import PhoneInput from "react-phone-input-2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface ContactProps {}
interface CountryInfo {
  country: string;
  nationality: string;
}
interface FormData {
  fullName: string;
  email: string;
  contactNumber?: string;
  subject: string;
  message: string;
}

const { REACT_APP_API_URL } = process.env;

const Contact: FC<ContactProps> = () => {
  const [nationality, setNationality] = useState("");
  const [phoneNumberWithoutDialCode, setPhoneNumberWithoutDialCode] =
    useState("");
  const [dialCode, setDialCode] = useState("");
  const [formData, setFormData] = useState<FormData>({
    fullName: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // PhoneNumber validation
  const handlePhoneChange = (value: string, country: CountryData) => {
    // Find the country info based on the country name
    const countryInfo: CountryInfo | undefined = countriesData.find(
      (info: CountryInfo) => info.country === country.name,
    );

    if (!countryInfo) {
      console.error(`Country info not found for name: ${country.name}`);
      return;
    }
    setNationality(countryInfo.nationality);
    const dialCode = country.dialCode;
    setDialCode(dialCode);

    const phoneNumberWithoutDialCodeValue = value.replace(dialCode, "");
    setPhoneNumberWithoutDialCode(phoneNumberWithoutDialCodeValue);
    setFormData((prevState) => ({
      ...prevState,
      contactNumber: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const { fullName, email, subject, message } = formData;

      const data: any = {
        fullName,
        email,
        subject,
        message,
        iss: process.env.REACT_APP_WORKPLAY_URL,
      };

      if (phoneNumberWithoutDialCode) {
        if (
          nationality === "Indian" &&
          phoneNumberWithoutDialCode.length !== 10
        ) {
          toast.error("Enter a valid phone number", {
            autoClose: 2000,
            className: "toastify-toast",
          });
          return;
        } else {
          data.phoneNumber = phoneNumberWithoutDialCode;
        }
      }

      if (dialCode) {
        data.isdCode = dialCode;
      }

      console.log("Form Data:", data);

      const response = await axios.post(`${REACT_APP_API_URL}/contact`, data);

      console.log("Response:", response.data);
      if (response.status === 200) {
        toast.success(
          "Thanks for reaching out! Your form has been submitted!",
          {
            autoClose: 3000,
            className: "toastify-toast",
          },
        );
      } else {
        throw new Error("Failed to submit the form. Please try again later.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.", {
        autoClose: 2000,
        className: "toastify-toast",
      });
    }

    setFormData({
      fullName: "",
      email: "",
      contactNumber: "",
      subject: "",
      message: "",
    });
  };

  return (
    <>
      <Navbar />
      <section
        id="contact"
        className="bg-black px-6 py-10 text-center font-bold text-white xsm:p-10 sm:px-10 sm:py-16 lg:p-16 xl:px-40 xl:py-12 3xl:py-16"
      >
        <h1 className="pb-3 text-3xl text-apple sm:text-4xl xl:pb-5 xl:text-heading">
          Contact
        </h1>
        <h3 className="m-auto mb-10 mt-2 max-w-[300px] text-base font-medium sm:max-w-full sm:text-lg lg:mb-5 xl:mb-10 xl:text-description">
          Get in touch with us. We're ready to help you find the best solutions.
        </h3>
        <div className="mb-8 block rounded-3xl lg:flex lg:h-[480px] lg:items-center lg:justify-center">
          <div className="hidden w-full lg:block lg:w-[30%] xl:w-[45%]">
            <iframe
              title="WorkPlay Studio Location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.2701413286036!2d77.57940577476015!3d12.890343187417626!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1561743cf959%3A0xcb6d5f6ef7b1e7c9!2sWorkPlay%20Studio%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1694608010783!5m2!1sen!2sin"
              width="100%"
              height="450px"
              style={{
                border: "0",
                borderTopLeftRadius: "20px",
                borderBottomLeftRadius: "20px",
              }}
              loading="eager"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="w-full rounded-xl bg-white px-4 py-6 text-black xsm:px-6 xsm:py-10 lg:flex lg:h-[450px] lg:w-[70%] lg:items-center lg:justify-center lg:rounded-l-none lg:rounded-r-3xl xl:w-[55%] xl:px-10 3xl:w-[40%]">
            <form onSubmit={handleSubmit} method="POST">
              <div className="grid grid-cols-1 text-left sm:grid-cols-2 sm:gap-x-2">
                <div>
                  <div>
                    <label
                      className="text-base font-semibold"
                      htmlFor="fullName"
                    >
                      Full Name
                    </label>
                    <span className="text-red-600"> *</span>
                  </div>
                  <input
                    className="mb-3 mt-1.5 h-[44px] w-full rounded-md border-2 border-gray-300 bg-white px-3 font-medium"
                    type="text"
                    name="fullName"
                    id="fullName"
                    placeholder="John Doe"
                    value={formData.fullName}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div>
                  <div>
                    <label className="text-base font-semibold" htmlFor="email">
                      Email Address
                    </label>
                    <span className="text-red-600"> *</span>
                  </div>
                  <input
                    className="mb-3 mt-1.5 h-[44px] w-full rounded-md border-2 border-gray-300 bg-white px-3 font-medium"
                    type="email"
                    name="email"
                    id="email"
                    placeholder="email@example.com"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div>
                  <div>
                    <label
                      className="text-base font-semibold"
                      htmlFor="contactNumber"
                    >
                      Phone Number
                    </label>
                  </div>
                  <div id="contact-phone">
                    <PhoneInput
                      country={"in"}
                      value={formData.contactNumber || ""}
                      onChange={handlePhoneChange}
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <label
                      className="text-base  font-semibold"
                      htmlFor="subject"
                    >
                      Subject
                    </label>
                    <span className="text-red-600"> *</span>
                  </div>
                  <input
                    className="mb-3 mt-1.5 h-[44px] w-full rounded-md border-2  bg-white px-3 font-medium"
                    type="text"
                    name="subject"
                    id="subject"
                    placeholder="Tell us how we can assist you"
                    value={formData.subject}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div>
                <div className="text-left">
                  <label className="text-base font-semibold" htmlFor="message">
                    Your Message
                  </label>
                  <span className=" text-red-600"> *</span>
                </div>
                <textarea
                  className="mb-3 mt-1.5 h-20 w-full rounded-md border-2 border-gray-300 bg-white p-3 text-[15px] font-normal sm:text-base"
                  name="message"
                  id="message"
                  placeholder="Write your message here"
                  value={formData.message}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="text-right">
                <button
                  type="submit"
                  className="h-[44px] w-full cursor-pointer rounded-md border-2 border-gray-800 bg-black px-6 text-base font-bold text-white hover:scale-1.2 lg:px-9"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="mb-5 w-full lg:mb-0 lg:hidden">
          <iframe
            title="WorkPlay Studio Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.2701413286036!2d77.57940577476015!3d12.890343187417626!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1561743cf959%3A0xcb6d5f6ef7b1e7c9!2sWorkPlay%20Studio%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1694608010783!5m2!1sen!2sin"
            width="100%"
            height="250px"
            style={{
              border: "0",
              borderRadius: "10px",
            }}
            loading="eager"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <ToastContainer />
      </section>
      <Footer />
    </>
  );
};

export default Contact;
