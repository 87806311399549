import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

interface ForgotPasswordProps {}

const { REACT_APP_S3_URL, REACT_APP_API_URL } = process.env;

const ForgotPassword: React.FC<ForgotPasswordProps> = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!email) {
      toast.error("Please fill your email address", {
        autoClose: 2000,
        className: "toastify-toast",
      });
    } else if (!validateEmail(email)) {
      toast.error("Invalid email address", {
        autoClose: 2000,
        className: "toastify-toast",
      });
      return;
    } else {
      const URL = `${REACT_APP_API_URL}/auth/reset-password`;
      if (!URL) {
        return;
      }
      try {
        const response = await axios.post(
          `${URL}`,
          { email: email },
          {
            headers: {
              "Content-Type": "application/json",
            },
          },
        );
        if (response.data.success === true) {
          toast.success("Password reset email sent, Please check your inbox.", {
            autoClose: 3000,
            className: "toastify-toast",
          });
          setEmail("");
          localStorage.removeItem("xSudTzMYmd");
        }
      } catch (error: any) {
        toast.error(error.response.data.message, {
          autoClose: 2000,
          className: "toastify-toast",
        });
        console.error("Error creating user:", error);
        setEmail("");
      }
    }
  };

  const handleLogoRedirect = () => {
    navigate("/");
  };

  return (
    <>
      <div className="min-h-screen items-center lg:flex">
        <div className="lg:h-screen lg:w-[50%] lg:bg-gray-100">
          <img
            className="m-7 h-[25px] cursor-pointer xsm:h-6 xl:mx-10"
            src={`${REACT_APP_S3_URL}/assets/workplay-logo.png`}
            alt="workplay-logo"
            onClick={handleLogoRedirect}
          />
          <div className="flex items-center justify-center lg:mt-20">
            <img
              className="h-[200px] xsm:h-[260px] sm:h-[320px] xl:h-[450px]"
              src={`${REACT_APP_S3_URL}/assets/forgot.png`}
              alt="forgot-pass"
            />
          </div>
        </div>
        <div className="m-auto flex items-center justify-center py-6 lg:w-[50%] lg:py-0">
          <div className="xxsm:w-[320px] w-[300px] xsm:w-[360px] sm:w-[400px] lg:w-[384px]">
            <h1 className="text-center text-2xl font-bold text-black lg:text-left lg:text-3xl">
              <strong className="relative before:bg-[#f7e9ce]">Forgot</strong>{" "}
              Password?
            </h1>
            <p className="m-auto mb-6 mt-3 text-center text-[17px] font-medium sm:mt-4 lg:text-left">
              Don't Worry! Just enter the email address you registered with to
              receive a password reset link.
            </p>
            <form onSubmit={handleSubmit} className="flex flex-col">
              <label className="font-medium">Email</label>
              <input
                type="email"
                name="email"
                className="xxsm:w-[320px] m-auto my-2 h-[44px] w-[300px] rounded-md bg-white px-3 text-base text-black xsm:w-[360px] sm:w-[400px] lg:w-[384px]"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                type="submit"
                className="xxsm:w-[320px] mt-2 flex h-[44px] w-[300px] items-center justify-center rounded-md border border-solid border-black bg-black text-base font-medium text-white hover:scale-1.2 xsm:w-[360px] sm:w-[400px] lg:w-[384px]"
              >
                Get reset link
              </button>
            </form>
            <p className="hidden py-4 text-center text-base font-medium text-black sm:flex sm:justify-center">
              Did you remember your password?{" "}
              <a
                href="/login"
                className="text-black underline hover:opacity-60"
              >
                <strong className="relative ml-1 text-base font-semibold before:bg-[#f7e9ce]">
                  Try logging in
                </strong>
              </a>
            </p>
            <p className="block pt-4 text-center text-base font-medium text-black sm:hidden">
              Did you remember your password?{" "}
            </p>
            <a
              href="/login"
              className="flex justify-center text-black underline hover:opacity-60 sm:hidden"
            >
              <strong className="relative  text-base font-semibold before:bg-[#f7e9ce]">
                Try logging in
              </strong>
            </a>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ForgotPassword;
