import React from "react";
import "../styles/Sections/WhatWeDo.css";
interface CardData {
  title: string;
  content: string;
  imageSrc: string;
  altText: string;
  cardImgClass: string;
}

const { REACT_APP_S3_URL } = process.env;

const cardsData: CardData[] = [
  {
    title: "Retain Your Customers",
    content:
      "We create games which enhance brand value and consumer loyalty. Our games create interaction between your brand and existing users.",
    imageSrc: `${REACT_APP_S3_URL}/assets/retain-customers.png`,
    altText: "retain-customers",
    cardImgClass: "retain-customers",
  },
  {
    title: "Acquire New Customers",
    content:
      "We create gamified digital marketing solutions that engage the audience with your brand, increasing your conversion rate.",
    imageSrc: `${REACT_APP_S3_URL}/assets/acquire-new-customers.png`,
    altText: "acquire-new-customers",
    cardImgClass: "acquire-new-customers",
  },
  {
    title: "Study Customer Insights",
    content:
      "We look at your entire customer lifecycle and use those insights to create compounding returns that drive more engaged customers.",
    imageSrc: `${REACT_APP_S3_URL}/assets/customer-insights.png`,
    altText: "customer-insights",
    cardImgClass: "customer-insights",
  },
  {
    title: "Add Value to Brand",
    content:
      "We help add value to your brand by implementing games to your marketing strategies that will make customers seek you out.",
    imageSrc: `${REACT_APP_S3_URL}/assets/add-value-to-brand.png`,
    altText: "add-value-to-brand",
    cardImgClass: "add-value-to-brand",
  },
];

const WhatWeDo: React.FC = () => {
  return (
    <section
      id="what-we-do"
      className="bg-crayola px-8 pb-12 pt-8 text-center text-black sm:px-10 sm:py-16 lg:p-16 xl:px-32 xl:py-16 3xl:py-28"
    >
      <h1 className="title mb-8 pb-3 text-[2rem] font-bold text-eerie-black xsm:pb-6 xsm:text-[2.2rem] sm:text-4xl xl:text-heading">
        What we do
      </h1>
      <div className="m-auto grid w-full grid-cols-1 flex-wrap items-center justify-center gap-y-8 text-center font-semibold md:w-[90%] md:grid-cols-2 md:justify-evenly lg:mb-6 lg:mt-12 lg:w-[80%] xl:w-full xl:grid-cols-4 xl:justify-evenly">
        {cardsData.map((card, index) => (
          <div
            className="card m-auto flex h-[245px] w-[270px] cursor-pointer flex-col overflow-hidden rounded-2xl border-2 border-black bg-white p-0"
            key={index}
          >
            <div className="card-inner">
              <h5 className="py-4 text-center uppercase">{card.title}</h5>
              <p className="card-content absolute flex flex-col items-center justify-center px-7 text-center font-semibold">
                {card.content}
              </p>
              <div className="card-image m-auto h-[200px] bg-[#d9d9d9]">
                <img
                  className={card.cardImgClass}
                  src={card.imageSrc}
                  alt={card.altText}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default WhatWeDo;
