import { useContext, useEffect, useState } from "react";
import { LoginContext } from "../../Context/LoginContext/LoginContext";
import { userContext } from "../../Context/LoginContext/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faEdit,
  faSignOutAlt,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const { REACT_APP_S3_URL, REACT_APP_API_URL } = process.env;

const SideBarFooter: React.FC = () => {
  const [profile, setProfile] = useState(false);
  const { user, setUser } = useContext(userContext);
  const navigate = useNavigate();
  const contextValue = useContext(LoginContext);
  const additionalMenuItems = [
    {
      name: "Logout",
      path: "/",
      icon: faSignOutAlt,
    },
  ];

  const handleSetUser = (data: any) => {
    setUser({
      email: data.email,
      fname: data.firstName,
      lname: data.lastName,
      phone: data.phoneNumber,
      isdcode: data.isdCode,
      img: {
        source: data.img.source,
        link:
          data.img.source === "bucket"
            ? `${REACT_APP_S3_URL}/uploads/profile/${data.img.link}`
            : data.img.link,
      },
      bio: data.bio,
      location: data.location,
      socials: data.socials,
      company: data.company,
    });
  };

  useEffect(() => {
    const token = localStorage.getItem("xSudTzMYmd");
    const fetchProfile = async () => {
      const URL = `${REACT_APP_API_URL}/auth/profile`;
      if (!URL) {
        return;
      }
      try {
        const response = await axios.get(URL, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.success === true) {
          contextValue?.login();
          handleSetUser(response.data.data);
          if (response.data.data.img.source === "oauth") {
            sessionStorage.setItem("ImgLink", response.data.data.img.link);
            sessionStorage.setItem("fname", response.data.data.firstName);
            sessionStorage.setItem("lname", response.data.data.lastName);
            sessionStorage.setItem("email", response.data.data.email);
          }

          if (response.data.data.img.source === "bucket") {
            sessionStorage.setItem(
              "ImgLink",
              `${REACT_APP_S3_URL}/uploads/profile/${response.data.data.img.link}`,
            );
            sessionStorage.setItem("fname", response.data.data.firstName);
            sessionStorage.setItem("lname", response.data.data.lastName);
            sessionStorage.setItem("email", response.data.data.email);
          }
        }
      } catch (error: any) {
        contextValue?.logout();
        localStorage.removeItem("xSudTzMYmd");
        sessionStorage.removeItem("ImgLink");
        sessionStorage.removeItem("lname");
        sessionStorage.removeItem("fname");
        sessionStorage.removeItem("email");
        console.log(error);
      }
    };
    if (token) {
      fetchProfile();
    }
  }, []);

  const handleProfileNavigate = () => {
    navigate("profile");
    setProfile(false);
  };

  return (
    <div className="absolute bottom-0 flex w-[320px] flex-col font-medium">
      <div className="mx-6 my-4 flex h-auto items-center justify-evenly rounded-lg bg-[#242525] py-2">
        <div className="flex items-center text-stone-100">
          <img
            src={
              sessionStorage.getItem("ImgLink") ||
              user.img.link ||
              `${REACT_APP_S3_URL}/uploads/profile/default-profile.png`
            }
            className="mr-3 h-10 w-10 rounded-full"
            alt="profile"
          />
          {sessionStorage.getItem("fname") &&
            sessionStorage.getItem("lname") &&
            sessionStorage.getItem("email") && (
              <div>
                <p className="text-sm">
                  {sessionStorage.getItem("fname") +
                    " " +
                    sessionStorage.getItem("lname")}
                </p>
                <p className="text-[12px] opacity-75">
                  {sessionStorage.getItem("email")}
                </p>
              </div>
            )}
        </div>
        <FontAwesomeIcon
          onClick={() => setProfile(!profile)}
          icon={faChevronRight}
          className="ml-2 cursor-pointer text-stone-100 opacity-75 hover:opacity-100"
        />
      </div>
      {profile && (
        <div className="bg-transperent absolute bottom-4 left-[340px] z-40 flex h-auto w-full flex-col rounded-lg bg-black px-4 text-stone-200">
          <div
            onClick={handleProfileNavigate}
            className="flex cursor-pointer items-center hover:scale-95 hover:opacity-60"
          >
            <FontAwesomeIcon className="" icon={faEdit} />
            <p className="p-4 font-poppins text-sm ">Edit Profile</p>
          </div>
          <div className="w-auto border-b border-[#d4d4d4]"></div>
          <button
            className="absolute right-4 top-2"
            onClick={() => setProfile(!profile)}
          >
            <FontAwesomeIcon
              className="h-[18px] text-stone-200"
              icon={faClose}
            />
          </button>
          <ul className="flex items-center py-4 text-sm text-stone-200">
            {additionalMenuItems.map((item, index) => (
              <li>
                <button
                  key={index}
                  onClick={() => {
                    if (item.name === "Logout") {
                      contextValue?.logout();
                      localStorage.clear();
                      sessionStorage.clear();
                      sessionStorage.removeItem("ImgLink");
                      sessionStorage.removeItem("fname");
                      sessionStorage.removeItem("lname");
                      sessionStorage.removeItem("email");
                      sessionStorage.removeItem("collapse");
                      navigate("/");
                    } else {
                      window.location.href = `${item.path}`;
                    }
                  }}
                  className="font-poppins hover:scale-95 hover:opacity-60"
                >
                  <FontAwesomeIcon className="pr-4" icon={item.icon} />
                  {item.name}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SideBarFooter;
