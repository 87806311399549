import React, { useState } from "react";
import "../styles/Sections/OurServices.css";

const { REACT_APP_S3_URL } = process.env;

const servicesData = [
  {
    title: "Gamified Digital Marketing",
    imageSrc: `${REACT_APP_S3_URL}/assets/gamified-digital-marketing.png`,
    content:
      "We provide customized and branded games across various genres, designed to enhance your brand visibility and effectively engage your target audience. We offer comprehensive end-to-end solutions that encompass campaign concepts, execution, and data collection. No matter the type of game you select, we can incorporate your logo and branding elements to give it a unique and personalized touch.",
    services:
      "Marketing Games | Brand Inculcated design of games | Custom Game Development",
  },
  {
    title: "In-App Gamification",
    imageSrc: `${REACT_APP_S3_URL}/assets/in-app-gamification.png`,
    content:
      "Gamification is the process of implementing gaming elements in your mobile app. It helps to boost engagement and keep users focussed on their goals. Importantly, it's a big part of optimising your app for retention rates, user sessions, app churn and return on ad spend. In-App gamification leverages the data mined from user behavior to incentivize desired user actions.",
    services: "In-App Gamification Solutions | Customized team-centric games",
  },
  {
    title: "Customized Games",
    imageSrc: `${REACT_APP_S3_URL}/assets/customized-games.png`,
    content:
      "Our customized games are meticulously crafted to align with your unique vision and goals. Whether you're looking to enhance brand engagement, educate your audience, or simply entertain, our team of experts will design and develop games tailored specifically to your needs. With our personalized touch, we seamlessly integrate your branding elements, ensuring a one-of-a-kind gaming experience that resonates with your audience.",
    services:
      "Branding Integration | Customized Game Development | Concept Ideation",
  },
  {
    title: "Web Development",
    imageSrc: `${REACT_APP_S3_URL}/assets/web-development.png`,
    content:
      "We design, develop, and maintain high-quality websites that yield tangible, measurable results. Our team of qualified professionals is dedicated to creating website solutions that not only enhance credibility but also foster brand trust. Regardless of the industry, a strong online presence can significantly impact the success of a business, and the well-crafted websites by us serve as a vital tool in making your company easily discoverable, providing comprehensive information about your business.",
    services: "Design | Build | Support | Maintenance | UI/UX | Brand kit",
  },
];

const OurServices: React.FC = () => {
  const [currentService, setCurrentService] = useState(0);

  const nextService = () => {
    setCurrentService((prevService) => (prevService + 1) % servicesData.length);
  };

  const prevService = () => {
    setCurrentService((prevService) =>
      prevService === 0 ? servicesData.length - 1 : prevService - 1,
    );
  };

  const service = servicesData[currentService];

  const isFirstSlide = currentService === 0;
  const isLastSlide = currentService === servicesData.length - 1;

  return (
    <section
      id="our-services"
      className="bg-[#d9d9d9] px-1 py-6 text-center font-bold text-[#292929] sm:px-10 sm:py-16 lg:p-16 xl:px-32 xl:py-12 3xl:py-28"
    >
      <h1 className="title mb-6 pb-3 text-[2rem] font-bold text-eerie-black xsm:text-[2.2rem] sm:mb-10 sm:pb-7 sm:text-4xl xl:text-heading">
        Our Services
      </h1>
      <div className="slider-container flex w-full items-center">
        <button
          onClick={prevService}
          className={`prev-button ${isFirstSlide ? "disabled cursor-not-allowed opacity-20" : ""}`}
          disabled={isFirstSlide}
        >
          &lt;
        </button>
        <div className="slider m-auto mb-6 h-auto w-10/12 rounded-2xl border-2 border-black bg-white xl:w-8/12">
          <div>
            <div className="flex h-10 w-full items-center rounded-t-[14px] bg-black p-5">
              <div className="mr-2 h-3.5 w-3.5 rounded-full border border-[#ff0000] bg-[#fe5f56]"></div>
              <div className="mr-2 h-3.5 w-3.5 rounded-full border border-[#e9ac2a] bg-[#ffff00]"></div>
              <div className="h-3.5 w-3.5 rounded-full border border-[#26c83f] bg-[#adff2f]"></div>
            </div>
            <div className="px-6 py-4 xsm:px-8 xsm:py-6 sm:p-10">
              <h2 className="mb-5 text-center text-xl xsm:text-2xl lg:text-3xl ">
                {service.title}
              </h2>
              <div className="my-2 items-center justify-between sm:my-3 lg:mt-4 lg:flex">
                <img
                  className="m-auto mb-4 h-32 xsm:h-36 sm:h-48 lg:h-52"
                  src={service.imageSrc}
                  alt={service.title}
                />
                <p className="text-[15px] lg:ml-4 lg:text-left lg:text-base">
                  {service.content}
                </p>
              </div>
              <strong className="relative">Our Services Include:</strong>
              <h4 className="mt-3">{service.services}</h4>
            </div>
          </div>
        </div>
        <button
          onClick={nextService}
          className={`next-button ${isLastSlide ? "disabled cursor-not-allowed opacity-20" : ""}`}
          disabled={isLastSlide}
        >
          &gt;
        </button>
      </div>
    </section>
  );
};

export default OurServices;
