import React, { useEffect, useState } from "react";
import SideBarFooter from "./SideBarFooter";
import SideMenu from "./SideMenu";
import { Tooltip } from "react-tooltip";
import CollapseSidebarMenu from "./CollapseSidebarMenu";
import CollapseSideBarFooter from "./CollapseSideBarFooter";
import { useNavigate } from "react-router-dom";

const { REACT_APP_S3_URL } = process.env;

const SideBar: React.FC = () => {
  const navigate = useNavigate();
  const [sidebarCollapse, setSidebarCollapse] = useState(false);

  useEffect(() => {
    const isCollapsed = sessionStorage.getItem("collapse") === "true";
    setSidebarCollapse(isCollapsed);
  }, []);

  const handleLogoRedirect = () => {
    navigate("/");
  };

  const handleSidebarCollapse = () => {
    setSidebarCollapse(true);
    sessionStorage.setItem("collapse", "true");
  };

  const handleSidebarExpand = () => {
    setSidebarCollapse(false);
    sessionStorage.removeItem("collapse");
  };

  return (
    <div className="hidden lg:flex">
      {sidebarCollapse || sessionStorage.getItem("collapse") ? (
        <div>
          <aside
            className={`flex h-svh w-[80px] flex-col border-r border-[#e4e4e4]`}
          >
            <div>
              <div className="flex h-20 items-center justify-center bg-[#1d1c1c]">
                <img
                  src={`${REACT_APP_S3_URL}/assets/WorkPlay_FaviCon.svg`}
                  className="cursor-pointer"
                  alt="workplay"
                  onClick={handleLogoRedirect}
                />
              </div>
              <div className="flex h-screen flex-col items-center bg-black py-8">
                <img
                  data-tooltip-id="sidebar"
                  data-tooltip-content="Expand Sidebar"
                  className="h-6 cursor-pointer opacity-75 hover:scale-105 hover:opacity-100"
                  src={`${REACT_APP_S3_URL}/assets/icons/expand-sidebar.png`}
                  alt="sidebar"
                  onClick={handleSidebarExpand}
                />
                <Tooltip
                  id="sidebar"
                  className="custom-tooltip"
                  noArrow
                  place="right"
                  style={{ fontSize: "14px", fontFamily: "Poppins" }}
                />
                <CollapseSidebarMenu />
                <div className="absolute bottom-10">
                  <CollapseSideBarFooter />
                </div>
              </div>
            </div>
          </aside>
        </div>
      ) : (
        <div>
          <aside
            className={`flex h-svh w-[320px] flex-col border-r border-[#e4e4e4] `}
          >
            <div className="h-screen">
              <div className="flex items-center justify-between bg-[#1d1c1c] px-8 py-7">
                <img
                  src={`${REACT_APP_S3_URL}/assets/wp-logo.png`}
                  className="h-[23px] cursor-pointer"
                  alt="workplay-studio-pvt-ltd"
                  onClick={handleLogoRedirect}
                />
                <img
                  data-tooltip-id="sidebar"
                  data-tooltip-content="Collapse Sidebar"
                  data-tooltip-delay-show={1000}
                  className="h-6 cursor-pointer opacity-75 hover:scale-105 hover:opacity-100"
                  src={`${REACT_APP_S3_URL}/assets/icons/sidebar-white.png`}
                  alt="sidebar"
                  onClick={handleSidebarCollapse}
                />
                <Tooltip
                  id="sidebar"
                  className="custom-tooltip"
                  noArrow
                  style={{ fontSize: "14px", fontFamily: "Poppins" }}
                />
              </div>
              <div className="h-full bg-black">
                <SideMenu />
                <SideBarFooter />
              </div>
            </div>
          </aside>
        </div>
      )}
    </div>
  );
};

export default SideBar;
