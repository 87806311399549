import React, { useState } from "react";
import { useEffect, useLayoutEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import {
  getAllCampaigns,
  AllCampaigns,
  getCampaign,
  getCampaignMetaStatistics,
  getCampaignCountryStatistics,
  CampaignMetaStatisticsData,
  CampaignCountryStatisticsData,
  getCampaignGraphData,
  CampaignGraphData,
} from "../../../features/Campaigns/campaignSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ReactCountryFlag from "react-country-flag";
import SimpleLineChart from "./SimpleLineChart";
import CountryPieChart from "./CountryPieChart";
import MiniLineAreaChart from "./MiniLineAreaChart";
import country from "country-list-js";
import Loader from "../../Loader";
const { REACT_APP_S3_URL } = process.env;

interface CampaignCountryStatistic {
  totalImpressions: number;
  country: string;
}

const formatDate = (date: any) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const Statistics = () => {
  const today = new Date();
  const fromDate = new Date(today.getFullYear(), today.getMonth(), 1);
  const toDate = today;
  toDate.setDate(toDate.getDate() + 1);
  const graphData = useAppSelector(CampaignGraphData);
  const [current, setCurrent] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const metadata = useAppSelector(CampaignMetaStatisticsData);
  const CountryData = useAppSelector(CampaignCountryStatisticsData) || [];
  const Campaigns = useAppSelector(AllCampaigns) || [];
  const dispatch = useAppDispatch();
  const location = useLocation();

  function getCountryColor(code: string) {
    switch (code) {
      case "IN": // India
        return "bg-[#ee8251]";
      case "US": // United States
        return "bg-[#555555]";
      case "GB": // United Kingdom
        return "bg-[#ecca51]";
      case "CA": // Canada
        return "bg-[#ff0000]";
      case "AU": // Australia
        return "bg-[#00aaff]";
      case "FR": // France
        return "bg-[#0055a4]";
      case "DE": // Germany
        return "bg-[#ffcc00]";
      case "JP": // Japan
        return "bg-[#bc002d]";
      case "CN": // China
        return "bg-[#de2910]";
      case "BR": // Brazil
        return "bg-[#009c3b]";
      case "RU": // Russia
        return "bg-[#0033a0]";
      case "IT": // Italy
        return "bg-[#008c45]";
      case "ES": // Spain
        return "bg-[#c60b1e]";
      case "MX": // Mexico
        return "bg-[#006847]";
      case "KR": // South Korea
        return "bg-[#003478]";
      case "NG": // Nigeria
        return "bg-[#008751]";
      case "ZA": // South Africa
        return "bg-[#007847]";
      case "AR": // Argentina
        return "bg-[#74acdf]";
      case "EG": // Egypt
        return "bg-[#d4af37]";
      // Add more country codes and colors as needed
      default:
        return "bg-[#cccccc]"; // Default color for unknown countries
    }
  }

  const total =
    CountryData?.reduce((total, item) => total + item.totalImpressions, 0) || 0;

  const COLORS = ["#ee8251", "#000000", "#ecca51"];

  const handleCampaignSelect = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const selectedCampaignId = event.target.value;
    const element = Campaigns.find((item) => item.id === selectedCampaignId);
    setCurrent(selectedCampaignId);
    if (element?.id && element.gameId) {
      dispatch(
        getCampaignMetaStatistics({
          ccid: element?.id,
          gameId: element.gameId,
        }),
      );
      dispatch(
        getCampaignCountryStatistics({
          ccid: element?.id,
          gameId: element.gameId,
        }),
      );
      dispatch(
        getCampaignGraphData({
          ccid: element.id,
          gameId: element.gameId,
          fromDate: formatDate(fromDate),
          toDate: formatDate(toDate),
        }),
      );
    }
  };

  const element = Campaigns.find((item) => item.id === current);

  useEffect(() => {
    dispatch(getAllCampaigns());
    if (Campaigns.length > 0) {
      setCurrent(Campaigns[0].id);
      dispatch(getCampaign(Campaigns[0].id)).then(() => {
        if (Campaigns[0].id && Campaigns[0].gameId) {
          dispatch(
            getCampaignMetaStatistics({
              ccid: Campaigns[0].id,
              gameId: Campaigns[0].gameId,
            }),
          );
          dispatch(
            getCampaignCountryStatistics({
              ccid: Campaigns[0].id,
              gameId: Campaigns[0].gameId,
            }),
          );
          dispatch(
            getCampaignGraphData({
              ccid: Campaigns[0].id,
              gameId: Campaigns[0].gameId,
              fromDate: formatDate(fromDate),
              toDate: formatDate(toDate),
            }),
          );
        }
      });
    } else {
      setLoading(false);
    }
  }, []);

  useLayoutEffect(() => {
    dispatch(getAllCampaigns());
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, [location.pathname, current]);

  const miniGraphData = graphData?.map(
    ({ totalImpressions, totalSubmissions, date }) => ({
      Impressions: totalImpressions,
      Submissions: totalSubmissions,
      date,
    }),
  );

  return !loading ? (
    <>
      {Campaigns.length > 0 ? (
        <section className="flex w-full flex-col gap-6 p-4 md:p-8 lg:flex-row">
          <div className="w-full lg:w-[65%]">
            <div className="mb-4">
              <div className="mb-2 flex flex-col items-start gap-2 md:flex-row md:items-center md:gap-4">
                <label
                  htmlFor="campaign-select"
                  className="block text-lg font-medium"
                >
                  Select project:
                </label>
                <select
                  id="campaign-select"
                  value={current}
                  onChange={handleCampaignSelect}
                  className="flex w-full justify-end rounded-md border p-2 text-left outline-none md:w-fit"
                >
                  {Campaigns.map((campaign, index) => (
                    <option key={index} value={campaign.id}>
                      {campaign.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {element?.gameId ? (
              <div>
                {
                  <div className="flex h-auto flex-col gap-4 md:h-24 md:flex-row">
                    <div className="flex w-full rounded-md bg-white p-4 shadow">
                      <div className="w-full md:w-[70%]">
                        <p className="mb-2 text-lg font-semibold text-stone-400">
                          Total views
                        </p>
                        <h2 className="text-2xl font-semibold">
                          {metadata?.impressions}
                        </h2>
                      </div>
                      <div className="relative top-4 w-full md:w-[30%]">
                        <MiniLineAreaChart
                          data={miniGraphData}
                          dataKey="Impressions"
                          filter="This Month"
                        />
                      </div>
                    </div>
                    <div className="flex w-full rounded-md bg-white p-4 shadow">
                      <div className="w-full md:w-[70%]">
                        <p className="mb-2 text-lg font-semibold text-stone-400">
                          Total Submissions
                        </p>
                        <h2 className="text-2xl font-bold">
                          {metadata?.submissions}
                        </h2>
                      </div>
                      <div className="relative top-4 w-full md:w-[30%]">
                        <MiniLineAreaChart
                          data={miniGraphData}
                          dataKey="Submissions"
                          filter="This Month"
                        />
                      </div>
                    </div>
                  </div>
                }

                {<SimpleLineChart Id={current} />}
              </div>
            ) : (
              <div className="left-1/2  m-auto  mt-20  flex  flex-col items-center justify-center overflow-hidden text-center sm:absolute">
                <p className=" font-poppins text-[15px] lg:text-lg">
                  Game Customization pending!
                </p>
                <img
                  src={`${REACT_APP_S3_URL}/assets/rocket-ignite.png`}
                  className=" h-64 text-center lg:h-[360px]"
                  alt="no data found"
                />
              </div>
            )}
          </div>
          {element?.gameId &&
            CountryData.length > 0 &&
            metadata?.impressions !== 0 && (
              <div className="w-full lg:w-[35%]">
                {<CountryPieChart data={CountryData} colors={COLORS} />}
                <div className="mt-8 h-fit w-full rounded-xl bg-white p-4 shadow">
                  <p className="mb-6 text-lg font-semibold">
                    Top Location for Audience
                  </p>
                  {total &&
                    CountryData?.map((data, index) => (
                      <div key={index} className="mb-4">
                        <div className="mb-2 flex items-center justify-between">
                          <div className="flex items-center">
                            <ReactCountryFlag
                              countryCode={data.country}
                              svg
                              style={{
                                width: "2em",
                                height: "2em",
                              }}
                              title={data.country}
                            />
                            <span className="ml-2 font-semibold">
                              {" "}
                              {country.findByIso2(data.country).name}
                            </span>
                          </div>
                          <span className="text-sm">
                            {((data.totalImpressions / total) * 100).toFixed(1)}
                            %
                          </span>
                        </div>
                        <div className="relative h-1.5 w-full rounded bg-gray-200">
                          <div
                            className={`absolute left-0 top-0 h-full rounded ${getCountryColor(data.country)} `}
                            style={{
                              width: `${((data.totalImpressions / total) * 100).toFixed(1)}%`,
                            }}
                          ></div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
        </section>
      ) : (
        <div className="  m-auto  flex h-screen flex-col items-center justify-center overflow-hidden text-center">
          <p className=" font-poppins text-[17px] lg:text-lg">
            You dont have any projects yet!
          </p>
          <img
            src={`${REACT_APP_S3_URL}/assets/nothing-here.png`}
            className=" h-56 text-center lg:h-[340px]"
            alt="no data found"
          />
        </div>
      )}
    </>
  ) : (
    <Loader />
  );
};

export default Statistics;
